import _ from 'underscore'

$ ->
  if $('.job-navbar').length > 0
    jobNavbar = $('.job-navbar')
    threshold = 0

    if $('.header').length > 0
      boundingClient = $('.header').get(0).getBoundingClientRect()
      threshold = boundingClient.height

    showOrHide = () ->
      if window.scrollY >= threshold
        jobNavbar.addClass('show-nav')
      else
        jobNavbar.removeClass('show-nav')

    window.onscroll = _.debounce(showOrHide)
    showOrHide()
