import jquery from "jquery";
import "cplook";
import "spectrum-colorpicker";
import { iframeResizer } from "iframe-resizer";
import "ekko-lightbox";
import bootstrapSlider from "bootstrap-slider";
import React from "react";
import ReactDOM from "react-dom";
import DOM from "react-dom-factories";
import createClass from "create-react-class";
import { Dispatcher } from "flux";
import _ from "underscore";
import Backbone from "backbone";
import PdfForm from "pdf_form";

window.jQuery = window.$ = jquery;
window.iFrameResize = iframeResizer;
import "ekko-lightbox";
window.Slider = bootstrapSlider;
window.React = React;
window.ReactDOM = ReactDOM;
window.FluxDispatcher = Dispatcher;
window.React.DOM = DOM;
window.React.createClass = createClass;
window._ = _;
window.Backbone = Backbone;

//stimulus
import "@stimulus/polyfills";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
const application = Application.start();
const context = require.context(
  "../../../assets/javascripts/controllers",
  true,
  /\.js$/
);
application.load(definitionsFromContext(context));

import TooltipController from "../javascripts/controllers/shared/tooltip_controller";
application.register("tooltip", TooltipController);

const elm = require("assets/elm/elm.js");

require("assets/javascripts/base/shared");
require("assets/javascripts/base/global/checkboxes.coffee");
require("assets/javascripts/base/global/click_to_copy_link.coffee");
require("assets/javascripts/base/global/clipboard_listener.coffee");
require("assets/javascripts/base/global/cocoon_additions.coffee");
require("assets/javascripts/base/global/handle_disable.coffee");
require("assets/javascripts/base/global/inputs.coffee");
require("assets/javascripts/base/global/js_depend.coffee");
require("assets/javascripts/base/global/signatures.coffee")(elm);
require("assets/javascripts/base/global/switch_input.coffee");
require("assets/javascripts/base/global/password_rules.coffee");
require("assets/javascripts/base/global/elm/generic.coffee");
require("assets/javascripts/components.coffee");
PdfForm(elm);

require("assets/javascripts/external/jquery_cycle2");

require("assets/javascripts/base/public/activate.coffee");
require("assets/javascripts/base/public/appointments.coffee");
require("assets/javascripts/base/public/file_area.coffee");
require("assets/javascripts/base/public/gallery.coffee");
require("assets/javascripts/base/public/ga_tracking.coffee");
require("assets/javascripts/base/public/geolocate.coffee");
require("assets/javascripts/base/public/geolocate.coffee");
require("assets/javascripts/base/public/microsite_form_helper.coffee");
require("assets/javascripts/base/public/job_navbar.coffee");
require("assets/javascripts/base/public/jobs.coffee");
require("assets/javascripts/base/public/public.coffee");
require("assets/javascripts/base/public/raw_copy.coffee");
require("assets/javascripts/base/public/scoring_set_navigation.coffee");
require("assets/javascripts/base/public/single_submit.coffee");
require("assets/javascripts/base/public/terms.coffee");
require("assets/javascripts/base/public/testimonial_widget.coffee");
require("assets/javascripts/base/public/timer.coffee");
