$ ->
  $('.file-result-area .filename').each ->
    $(this).data('original', $(this).val())

  $('.file-area').on 'done', (e, data)->
    fa = $(this)
    return if fa.data('name') != data.name
    ra = fa.closest('fieldset').find('.file-result-area')
    result = ra.find('.filename')
    if data.value
      filename = decodeURIComponent(data.value.split(/[\\\/]/).pop())
      if filename.length > 20
        filename = "#{filename.substring(0, 20)}..."
      result.html filename
    else
      result.html result.data('original')
    fa.collapse 'hide'
    ra.collapse 'show'

  $('.file-area').on 'reset', (e, name)->
    fa = $(this)
    return if fa.data('name') != name
    ra = fa.closest('fieldset').find('.file-result-area')
    result = ra.find('.filename')
    fa.collapse 'show'
    ra.collapse 'hide'
    fa.find('input.reset').val('')
    result.html result.data('original')

  $('.file-area .input.file input').on 'change', (e)->
    fa = $(this).closest('.file-area')
    fa.trigger 'done', { name: fa.data('name'), value: e.target.value }

  $('.file-result-area .remove').on 'click', ->
    fa = $(this).closest('fieldset').find('.file-area')
    fa.trigger 'reset', fa.data('name')
    false
