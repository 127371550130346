# Disables submit buttons after form is submitted, preventing double submits, & shows loading icon.
# 10/4 - Currently used only on public pages (apps, scoring sets, signup)

$(->
  $('form.single_submit').submit(->
    $(this).find('input[type="submit"], button[type="submit"]')
      .prop('disabled','disabled')
      .after("<div class=\"ajaxload\"></div>")
    true
  )
)
