$(->
  if $('.has-scoring-set-navigation').length > 0
    _dash = $('#question-dashboard')
    _questions = $('fieldset.inputs')
    _total = _questions.children('div.input').length
    _current_question = 1
    _skipped_mode = false
    _dash.find('.question-total').text(_total)

    update_status = ->
      _dash.find('.question-num').text(_current_question)
      slen = _questions.find('.input.skipped').length
      if slen > 0
        _dash.find('.skipped-count').show()
      else
        _dash.find('.skipped-count').hide()
      _dash.find('.skipped-total').text(slen)

    clear_next_button = (val)->
      $('fieldset.form-actions input').prop('disabled', false)
      $('fieldset.form-actions button.advance').removeClass('disabled')
      $('fieldset.form-actions button.advance').text val

    lock_next_button = ->
      $('fieldset.form-actions button.advance').text('Next')
      $('fieldset.form-actions input').prop('disabled', true)
      $('fieldset.form-actions button.advance').addClass('disabled')

    update_actions = ->
      if _current_question > 1
        $('button.go-back').addClass('enabled')
      else
        $('button.go-back').removeClass('enabled')
      if _skipped_mode && _current_question >= _total &&
         _questions.find('.input.skipped').length < 1
        $('fieldset.form-actions').removeClass('dual')
        $('fieldset.form-actions button.advance').hide()
        $('fieldset.form-actions input').show()
      else if _current_question >= _total && _questions.find('.input.skipped').length < 1
        $('fieldset.form-actions button.advance').hide()
        $('fieldset.form-actions input').show()
      else
        $('fieldset.form-actions input').hide()
        $('fieldset.form-actions button.advance').show()
      q = _questions.children('div.input').eq(_current_question - 1)
      if q.hasClass('required') && !question_filled(q)
        lock_next_button()
      else
        if question_filled(q)
          clear_next_button('Next')
        else
          clear_next_button('Skip')

    question_filled = (q)->
      if q.hasClass('radio_buttons')
        answered = $.grep q.find('input'), (el)->
          $(el).prop('checked')
        answered.length > 0
      else if q.hasClass('text')
        q.find('textarea').val().length > 0
      else
        true

    mark_skipped = ->
      q = _questions.children('div.input').eq(_current_question - 1)
      if question_filled(q)
        q.removeClass('skipped')
      else
        q.addClass('skipped')

    activate_question = (num)->
      _current_question = num
      q = _questions.children('div.input').eq(num - 1)
      _questions.children('div.input').removeClass('active')
      q.addClass('active')
      update_actions()
      update_status()

    activate_skipped_mode = ->
      _skipped_mode = true
      $('fieldset.form-actions').addClass('dual')
      _current_question = 0

    next_question = ->
      if _current_question >= _total && !_skipped_mode
        activate_skipped_mode()
      if _skipped_mode
        next_q = _total
        _questions.children('div.input').each (i, el)->
          num = i + 1
          if num > _current_question && $(el).hasClass('skipped') && num < next_q
            next_q = num
        if next_q == _total && _questions.children('div.input.skipped').length > 0
          next_q = _questions.children('div.input.skipped').first().index() + 1
        next_q
      else
        _current_question + 1

    $('fieldset.form-actions button.advance').on 'click', ->
      if $(this).hasClass('disabled')
        return false
      mark_skipped()
      activate_question(next_question())
      $.scrollTo($('#question-dashboard'), 'fast')
      false

    $('fieldset.form-actions button.go-back').on 'click', ->
      if _skipped_mode && !$('fieldset.form-actions').hasClass('dual')
        $('fieldset.form-actions').addClass('dual')
        $('fieldset.form-actions button.advance').show()
      activate_question(_current_question - 1)
      $.scrollTo($('#question-dashboard'), 'fast')
      false

    choose_answer = ->
      $(this).closest('.input.radio_buttons').removeClass('skipped')
      $(this).closest('.input.radio_buttons').find('label.choice').removeClass('btn-primary')
      $(this).addClass('btn-primary')
      clear_next_button('Next')
    $('.input.radio_buttons label.choice').on 'click', choose_answer
    $('.input.radio_buttons label.choice').on 'touchstart', choose_answer

    $('.input.text textarea').on 'keyup', ->
      if $(this).val().length > 0
        $(this).closest('.input.text').removeClass('skipped')
        clear_next_button('Next')
      else
        lock_next_button()

    activate_question(1)
    $('.input.radio_buttons label.choice').addClass('btn btn-default')
    $('.has-scoring-set-navigation').addClass('enabled')
)
