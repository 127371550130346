# CAREERS PAGE PHOTOS

$( ->
  color_primary = $('#primary-color-holder').data('color')

  $('.gallery').cycle({
    fx: 'fade'
    slides: '.photo',
  })

  $('.gallery-v2').cycle({
    fx: 'fade'
    pager: $('#gallery_pager_container'),
    pagerTemplate: '<div style="border-color:' + color_primary + '"><div class="fill" style="background-color:' + color_primary + '"></div></div>'
  })


  primary_video = $('.photo-area .photo.primary iframe')
  other_videos = $('.photo-area .photo-wall .photo iframe')
  pv_container = $('.photo-area')
  $('.photo-area .photo iframe').each ->
    $(this)
      .data('aspectRatio', this.height / this.width)
      .removeAttr('height')
      .removeAttr('width')
  $(window).resize ->
    newWidth = pv_container.width()
    primary_video.each ->
      $el = $(this)
      $el.width(newWidth)
        .height(newWidth * $el.data('aspectRatio'))
    other_videos.each ->
      $el = $(this)
      $el.height(150)
        .width(150 / $el.data('aspectRatio'))

  $(window).resize()

  $(document).delegate '*[data-toggle="lightbox"]', 'click', (e)->
    e.preventDefault()
    $(this).ekkoLightbox()
)
