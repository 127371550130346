$(->
  $(document).on 'click', '#js-toggle-card-info', ->
    if $(this).closest('.card-info-container').find('.is-hidden').length > 0
      $(this).closest('.card-info-container').children('.signup-sub').toggleClass('is-hidden is-visible')
      return false
    else
      return false

  window.iFrameResizer =
    onReady: ->
      $('#job_table a,input[type="submit"]').on 'click', ->
        window.parentIFrame.sendMessage('resetScroll')
        true
)
