# Timer for timed assessments

$( ->
  pad2 = (number)->
    if (number < 10)
      '0' + number
    else
      number

  if $('#timer').length > 0
    $('#timer').data('limit', parseInt($('#timer').attr('data-stop')) - parseInt($('#timer').attr('data-start')))
    $('#timer').data('update_timer', ->
      $.get($('#timer').data('pulse'), (data, textStatus, jqXHR)->
        diff = parseInt($('#timer').data('limit')) - parseInt(data.elapsed)
        $('#timer #minutes').html(Math.floor(diff / 60))
        $('#timer #seconds').html(pad2(diff % 60))
        if diff <= 0
          $('#' + $('#timer').attr('data-submit')).submit()
        else
          setTimeout("$('#timer').data('update_timer')()",1000)
      , 'json')
    )
    $('#timer').data('update_timer')()
)
