# GEOLOCATOR FOR JOB AGGREGATORS

$(->
  return if location.search.indexOf('site_template_id') > -1
  return unless navigator.geolocation && (typeof(Storage) != "undefined")

  origin = $('meta[name="transmit-origin"]').attr('content')

  geocode = (position, success)->
    $.ajax
      url: "#{origin}/services/geolocate"
      data:
        lat: position.coords.latitude
        lng: position.coords.longitude
        radius: 25
      dataType: 'json'
      success: (data)->
        return unless data.length > 0
        success(data)

  if $('body').hasClass('services')
    geolocate = $('.microsite.services #geolocate')
    return unless geolocate.length > 0

    navigator.geolocation.getCurrentPosition (position)->
      geocode position, (data)->
        parent.postMessage
          service: 'geolocate'
          result: data[0].zip
        , geolocate.data('origin')

  else
    return unless $('.microsite input[name="z"]').length > 0 &&
                  sessionStorage.cp_geolocated != '1'

    sessionStorage.cp_geolocated = '1' # we only want to run this once

    zip_search = (zip)->
      $('#aggregate_job_location').modal('hide')
      $('.microsite input[name="z"]').first().val(zip)
      $('.microsite input[name="z"]').first().closest('form').submit()

    if origin == location.origin
      navigator.geolocation.getCurrentPosition (position)->
        geocode position, (data)->
          zip_search data[0].zip
    else
      $(window).on 'message', (ev)->
        event = ev.originalEvent
        if event.origin == origin && event.data? && event.data.service == 'geolocate'
          zip_search event.data.result
      $('body').append("<iframe id=\"geolocate\" class=\"service geolocate\"
                        src=\"#{origin}/services/geolocate?key=#{location.origin}\"
                        scrolling=\"no\" frameborder=\"0\" allowtransparency=\"true\"
                        title=\"Intentionally blank\" aria-hidden=\"true\"></iframe>")
)
