# TODO - brittany 10/5 - I'm not entirely sure what this is. A RawCopyBlock is a Liquid block we define in site_templates_helper.rb. Since this is a Liquid thing, I'm going to place it in public.
$(->
  $(document).on 'click', '.raw_copy a.show_copy_area', (ev)->
    ev.preventDefault()
    if $(this).parent().find('.raw_result').length > 0
      $(this).html($(this).data('orig-text'))
      $(this).parent().find('.content').show()
      $(this).parent().find('.raw_result').remove()
    else
      $(this).after('<textarea class="raw_result"></textarea>')
      $(this).parent().find('.raw_result').val($(this).parent().find('.content').html())
      $(this).parent().find('.content').hide()
      $(this).data('orig-text', $(this).html())
      $(this).html('Right-click below and choose Copy')
      $(this).parent().find('.raw_result').focus().select()
    return false
)
