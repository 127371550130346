$ ->
  customColors = (el, parent, remove) ->
    color = $('#primary-color-holder').data('color')

    if remove
      color = ''

    el.css('border-color', color)
    parent.find('label').css('color', color)
    parent.find('legend span').css('color', color)



  if ('.base-form').length > 0
    $('.base-form input').on('focus', () ->
      parent = $(this).closest('.form-group')
      parent.addClass('focused')
      customColors($(this), parent)
    )

    $('.base-form input').on('blur', () ->
      parent = $(this).closest('.form-group')
      parent.removeClass('focused')
      customColors($(this), parent, true)
    )

    $('.base-form .location-fieldset input, .base-form .location-fieldset select').on('focus', () ->
      parent = $(this).closest('.location-fieldset')
      parent.addClass('focused')
      customColors($(this), parent)
    )

    $('.base-form .location-fieldset input, .base-form .location-fieldset select').on('blur', () ->
      parent = $(this).closest('.location-fieldset')
      parent.removeClass('focused')
      customColors($(this), parent, true)
    )
