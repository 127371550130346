import cplook from 'cplook'
import moment from 'moment-timezone'

loadScheduler = (timezone, timezoneAbbr, availability, error = null) ->
  elm = require('../../../elm/elm.js')
  elm.AppointmentsMain.embed(
    $('#appointment_scheduler').get()[0],
    $.extend($('#appointment_scheduler').data('flags'), {
      timezone: timezone,
      timezoneAbbr: timezoneAbbr,
      availability: availability,
      mobileSwitchpoint: cplook.mobilePivot
      error: error
    }
    )
  )

$ ->
  if $('#appointment_scheduler').length > 0
    appointmentId = $('#appointment_scheduler').data('flags').appointmentId

    timezone = moment.tz.guess()
    timezoneAbbr = moment.tz(timezone).zoneAbbr()

    $.ajax(
      url: "/appointments/#{appointmentId}/availability"
      type: "GET"
      dataType: 'json'
      cache: false
      data: { timezone: timezone }
      success: (data)->
        loadScheduler(timezone, timezoneAbbr, data)
      error: (jqXHR, textStatus, errorThrown) ->
        loadScheduler(timezone, timezoneAbbr, [], "Error retrieving appointment availability")

    )
