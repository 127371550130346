$(->
  $(document).on 'click', 'div.terms-header .nav.nav-tabs li:not(.active)', (ev)->
    current_active = $('div.terms-header .nav.nav-tabs li.active')
    current_active_name = $(current_active).attr('id').split('_tab')[0]

    new_active = $(this)
    new_active_name = $(new_active).attr('id').split('_tab')[0]

    $(current_active).removeClass('active')
    $(new_active).addClass('active')

    $('.terms-body').find("##{current_active_name}_body").addClass('inactive')
    $('.terms-body').find("##{new_active_name}_body").removeClass('inactive')

    false

)
