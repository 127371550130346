$(->
  return unless _gaq?

  urchin_data = $('#ga_script').data('urchin')
  if urchin_data?
    if urchin_data.utm_campaign?
      _gaq.push(['set', 'campaignName', urchin_data.utm_campaign])
    if urchin_data.utm_source?
      _gaq.push(['set', 'campaignSource', urchin_data.utm_source])
    if urchin_data.utm_medum?
      _gaq.push(['set', 'campaignMedium', urchin_data.utm_medium])

  campaign_data = $('#ga_script').data('campaign')
  if campaign_data?
    _gaq.push(['_setCustomVar', 1, 'Source Type', campaign_data.type, 2])
    _gaq.push(['_setCustomVar', 2, 'Source Value', campaign_data.value, 2])

  events_data = $('#ga_script').data('events')
  if events_data?
    jQuery.each events_data, ()->
      _gaq.push(['_trackEvent', this.category, this.action, this.label])

  events_data = $('#ga_script').data('submit-events')
  if events_data?
    $(document).on 'click', 'a.indeed-apply-button,input#app_submit_action,input[type="submit"]', ->
      jQuery.each events_data, ()->
        _gaq.push(['_trackEvent', this.category, this.action, this.label])

  _gaq.push(['_trackPageview'])
)
